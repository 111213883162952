<template>
  <div class="calendar-wrapper" v-loading="loading">
    <div class="calendar-header">
      <div class="calendar-header-l">
        <div class="calendar-label" v-if="$slots.default">学校</div>
        <div class="mr" v-if="$slots.default">
          <slot></slot>
        </div>
        <template v-if="showType === 'month'">
          <div class="calendar-label">年月</div>
          <div class="calendar-select">
            <div class="calendar-icon" @click="changeMonth(-1)"><i class="el-icon-arrow-left"></i></div>
            <el-date-picker v-model="value1" type="month" value-format="yyyy-MM" placeholder="选择月" size="mini" :clearable="false" style="width: 120rem" @change="handleChangeDate"> </el-date-picker>
            <div class="calendar-icon" @click="changeMonth(1)"><i class="el-icon-arrow-right"></i></div>
          </div>
        </template>
        <template v-else-if="showType === 'week'">
          <div class="calendar-select">
            <div class="calendar-icon" @click="changeWeek(-1)"><i class="el-icon-arrow-left"></i></div>
            <div class="calendar-text">{{ `${weekList[0].year}年${weekList[0].month}月${weekList[0].day}日` }}~{{ `${weekList[6].year}年${weekList[6].month}月${weekList[6].day}日` }}</div>
            <div class="calendar-icon" @click="changeWeek(1)"><i class="el-icon-arrow-right"></i></div>
          </div>
        </template>
        <template v-else>
          <div class="calendar-label">日期</div>
          <el-date-picker v-model="value3" type="date" placeholder="选择日期" size="mini" :clearable="false" value-format="yyyy-MM-dd" @change="handleChangeDate"> </el-date-picker>
        </template>
        <div class="ml" v-if="$slots.right">
          <slot name="right"></slot>
        </div>
      </div>
      <div class="calendar-header-r">
        <el-button size="mini" v-for="(item, index) in showTypeArr" :key="index" :type="item.value === showType ? 'primary' : ''" @click="handleChangeType(item.value)">{{ item.label }}</el-button>
      </div>
    </div>
    <div class="calendar-content">
      <template v-if="showType === 'month'">
        <div class="calendar-row title-row">
          <div class="item" v-for="(item, index) in weekName" :key="index">{{ item }}</div>
        </div>
        <div class="calendar-row month-row">
          <div class="item" :class="[item.isToday && 'today', !item.isCurrentMonth && 'other']" v-for="(item, index) in monthList" :key="index">
            <div class="date">{{ item.day }}</div>
            <div class="info">
              <el-tooltip
                effect="dark"
                :content="`${child.startTime}${child.studentInfo.student_name}${child.chamberInfo.building_name}${child.chamberInfo.chamber_name}`"
                placement="top"
                v-for="child in item.list"
                :key="child.id"
              >
                <div class="p" @click="handleShow(child)">
                  <span class="time">{{ child.startTime }}</span>
                  <span class="name">{{ child.studentInfo.student_name }}</span>
                  <span class="address">{{ child.chamberInfo.building_name }}{{ child.chamberInfo.chamber_name }}</span>
                </div>
              </el-tooltip>
            </div>
            <div class="arrow" v-if="item.list.length > 3" @click="handleClickMonthDay(item)">
              <i class="el-icon-d-arrow-left"></i>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="showType === 'week'">
        <div class="calendar-row week-row">
          <template v-for="item in weekList">
            <div class="item" :class="[item.isToday && 'today']">
              <div class="title">
                <div>
                  <span class="name">{{ item.name }}</span>
                  <span class="date">{{ item.month }}月{{ item.day }}日</span>
                </div>
              </div>
              <div class="info">
                <el-tooltip
                  effect="dark"
                  :content="`${child.startTime}${child.studentInfo.student_name}${child.chamberInfo.building_name}${child.chamberInfo.chamber_name}`"
                  placement="top"
                  v-for="child in item.list"
                  :key="child.id"
                >
                  <div class="p" @click="handleShow(child)">
                    <span class="time">{{ child.startTime }}</span>
                    <span class="name">{{ child.studentInfo.student_name }}</span>
                    <span class="address">{{ child.chamberInfo.building_name }}{{ child.chamberInfo.chamber_name }}</span>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="day-info">
          <div class="day-title">
            <span class="name">{{ dayList.name }}</span>
            <span class="date">{{ dayList.month }}月{{ dayList.day }}日</span>
          </div>
          <div class="day-content">
            <div class="day-inner">
              <div class="time-box flex-column justify-between">
                <div class="item">{{ startTime }}:00</div>
                <div class="item">{{ endTime }}:00</div>
              </div>
              <div class="list-box">
                <div class="list-item" :class="judgeStatus(item)" v-for="item in dayList.list" :key="item.id" @click="handleShow(item)">
                  <div class="status flex-center">{{ item.psychology_status_text }}</div>
                  <div>
                    <div class="item"><i class="el-icon-time"></i>{{ item.startTime }}-{{ item.endTime }}</div>
                    <div class="item"><i class="el-icon-user"></i>{{ item.studentInfo.student_name }}</div>
                    <div class="item"><i class="el-icon-location-outline"></i>{{ item.chamberInfo.building_name }}{{ item.chamberInfo.chamber_name }}</div>
                  </div>
                </div>
                <t-result type="empty" v-if="!dayList.list.length"></t-result>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'calendar',
  props: {
    date: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    isMondayStart: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    start: {
      type: Number,
      default: 8
    },
    end: {
      type: Number,
      default: 23
    }
  },
  data() {
    return {
      startTime: 8,
      endTime: 23,
      height: 200,
      value1: '',
      value2: '',
      value3: '',
      monthDay: [31, 0, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      weekName: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      year: '',
      month: '',
      day: '',
      showType: 'month', // month week day
      showTypeArr: [
        { value: 'month', label: '月' },
        { value: 'week', label: '周' },
        { value: 'day', label: '日' }
      ],
      monthList: [],
      weekList: [],
      dayList: {}
    }
  },
  watch: {
    list(val) {
      if (this.showType === 'day') {
        this.getTime(val[0].list)
      } else {
        if (this.showType === 'month') {
          this.initData(val, this.monthList)
        } else if (this.showType === 'week') {
          this.initData(val, this.weekList)
        }
      }
    },
    monthList(val) {
      let len = val.length
      if (len) {
        this.$emit('change', [val[0].date, val[len - 1].date], 0)
      }
    },
    weekList(val) {
      let len = val.length
      if (len) {
        this.$emit('change', [val[0].date, val[len - 1].date], 0)
      }
    },
    dayList(val) {
      this.$emit('change', [val.date, val.date], 1)
    }
  },
  created() {
    this.init()
  },
  methods: {
    getTime(arr) {
      if (arr.length) {
        let startArr = arr.map(item => item.startTime.split(':').map(Number)[0])
        let endArr = arr.map(item => {
          const newArr = item.endTime.split(':').map(Number)
          return newArr[1] > 0 ? newArr[0] + 1 : newArr[0]
        })
        const [start, end] = [Math.min(...startArr), Math.max(...endArr)]
        this.startTime = start
        this.endTime = start === end ? end + 1 : end
      } else {
        this.startTime = this.start
        this.endTime = this.end
      }
      const label = []
      for (let i = this.startTime; i <= this.endTime; i++) {
        label.push({
          tag: `${this.fill(i)}:00`,
          time: i
        })
      }
      this.dayList.label = label

      this.dayList.list = arr
    },
    initData(arr, obj) {
      obj.forEach(item => {
        arr.map(child => {
          if (item.date === child.date) {
            item.list = child.list
          }
        })
      })
    },
    init() {
      if (this.isMondayStart) {
        this.weekName.push('星期日')
      } else {
        this.weekName.unshift('星期日')
      }
      const { year, month, day, timeStamp } = this.formatDate()
      this.year = year
      this.month = month
      this.day = day
      this.value1 = year + '-' + this.fill(month)
      this.value2 = timeStamp
      this.value3 = year + '-' + this.fill(month) + '-' + this.fill(day)
      this.showCalendar(year, month, this.getDayWeekIndex(year, month))
    },
    formatDate(dateVal) {
      let val
      if (typeof dateVal === 'string') {
        val = dateVal.replace(/\./g, '/').replace(/_/g, '/').replace(/-/g, '/')
      } else if (typeof dateVal === 'number') {
        const len = (dateVal + '').length
        if (len === 13) {
          val = dateVal
        } else if (len === 10) {
          val = dateVal * 1000
        }
      }
      const curDate = val ? new Date(val) : new Date()
      const [year, month, day, timeStamp] = [curDate.getFullYear(), curDate.getMonth() + 1, curDate.getDate(), this.getStartTime(curDate.getTime())]
      return { year, month, day, date: `${year}-${this.fill(month)}-${this.fill(day)}`, timeStamp }
    },
    // 判断某年是否是闰年
    isLeap(year) {
      return (year % 4 === 0 && year % 100 != 0) || year % 400 === 0
    },
    // 获取当前月天数
    getDays(year, month) {
      return month == 2 ? (this.isLeap(year) ? 29 : 28) : this.monthDay[month - 1]
    },
    fill(num) {
      return num > 9 ? num : '0' + num
    },
    // 判断某年某月某日是星期几，默认日为1号
    getDayWeekIndex(year, month, day = 1) {
      let sum = 0
      sum += (year - 1) * 365 + Math.floor((year - 1) / 4) - Math.floor((year - 1) / 100) + Math.floor((year - 1) / 400) + day
      for (let i = 0; i < month - 1; i++) {
        sum += this.monthDay[i]
      }
      if (month > 2) {
        if (this.isLeap(year)) {
          sum += 29
        } else {
          sum += 28
        }
      }
      let num = sum % 7
      if (this.isMondayStart) {
        num -= 1
        if (num < 0) {
          num = 6
        }
      }
      // this.isMondayStart ? '余数为0代表那天是周一，以此类推' : '余数为0代表那天是周日，为1代表是周一，以此类推'
      return num
    },
    // 显示日历
    showCalendar(year, month, firstDay) {
      this.getMonthList(year, month, firstDay)
    },
    getMonthList(year, month, firstDay) {
      const daysArr = this.getDays(year, month)
      const { year: noWYear, month: nowMonth, day: NowDay } = this.formatDate()
      /*添加日期部分*/
      const arr = []
      // 上月日历显示
      const prevNum = firstDay - 1
      if (prevNum >= 0) {
        let prevMonth = month - 1,
          prevYear = year
        if (prevMonth < 1) {
          prevMonth = 12
          prevYear = year - 1
        }
        const { timeStamp } = this.formatDate(`${year}-${month}-1`)
        for (let i = prevNum; i >= 0; i--) {
          let newTimeStamp = timeStamp - (firstDay - i) * 1000 * 60 * 60 * 24
          const { day: newDay, year: newYear, month: newMonth } = this.formatDate(newTimeStamp)
          const isToday = newYear === noWYear && newMonth === nowMonth && newDay === NowDay ? true : false
          arr[i] = {
            date: `${prevYear}-${this.fill(prevMonth)}-${this.fill(newDay)}`,
            year: prevYear,
            month: prevMonth,
            day: newDay,
            timeStamp: newTimeStamp,
            isToday,
            isCurrentMonth: false,
            list: []
          }
        }
      }
      // for (let i = 0; i < firstDay; i++) {
      //   //对1号前空白格的填充
      //   arr.push({ list: [] })
      // }

      for (let i = 1; i <= daysArr; i++) {
        const isToday = year === noWYear && month === nowMonth && i === NowDay ? true : false
        arr.push({
          date: `${year}-${this.fill(month)}-${this.fill(i)}`,
          year,
          month,
          day: i,
          timeStamp: this.getStartTime(this.formatDate(`${year}-${month}-${i}`).timeStamp),
          isToday,
          isCurrentMonth: true,
          list: []
        })
      }
      // 下月日历显示
      const changLine = (firstDay + daysArr) % 7
      if (changLine !== 0) {
        //添加结束，对该行剩余位置的空白填充
        // for (let i = changLine; i < 7; i++) {
        //   arr.push({ list: [] })
        // }
        const NextNum = 7 - changLine
        let nextMonth = month + 1,
          nextYear = year
        if (nextMonth > 12) {
          nextMonth = 1
          nextYear = year + 1
        }
        const { timeStamp } = this.formatDate(`${year}-${month}-${daysArr}`)
        for (let i = 0; i < NextNum; i++) {
          let newTimeStamp = timeStamp + (i + 1) * 1000 * 60 * 60 * 24
          const { day: newDay, year: newYear, month: newMonth } = this.formatDate(newTimeStamp)
          const isToday = newYear === noWYear && newMonth === nowMonth && newDay === NowDay ? true : false
          arr.push({
            date: `${nextYear}-${this.fill(nextMonth)}-${this.fill(newDay)}`,
            year: nextYear,
            month: nextMonth,
            day: newDay,
            timeStamp: newTimeStamp,
            isToday,
            isCurrentMonth: false,
            list: []
          })
        }
      }
      this.monthList = arr
    },
    // 设置时间戳为 00:00:00
    getStartTime(time) {
      const nowTimeDate = new Date(time)
      return nowTimeDate.setHours(0, 0, 0, 0)
    },
    // 获取时间戳与当前周的星期几
    getDayTimeStampIndex(timeStamp) {
      const curDate = timeStamp ? new Date(timeStamp) : new Date()
      const curTimeStamp = this.getStartTime(Number(curDate)) // 当前日期时间戳
      const { year, month, day } = this.formatDate(curTimeStamp)
      const index = this.getDayWeekIndex(year, month, day)
      return { timeStamp: curTimeStamp, index }
    },
    handleShowWeek() {
      let timeStamp = this.value2
      let week = []
      for (let i = 0; i < 7; i++) {
        let weekObj = {
          name: this.weekName[i],
          date: '',
          timeStamp: '',
          year: '',
          month: '',
          day: '',
          list: []
        }
        week.push(weekObj)
      }
      const { timeStamp: curTimeStamp, index: week_idx } = this.getDayTimeStampIndex(timeStamp)
      week[week_idx].timeStamp = curTimeStamp
      const [leftNum, rightNum] = [week_idx - 1, 6 - week_idx]
      // 本周内当前日期的前几天的数量
      // 本周内当前日期的后几天的数量
      // week_idx 为 0时（起始日） 不执行
      if (leftNum >= 0) {
        for (let left = leftNum; left >= 0; left--) {
          week[left].timeStamp = curTimeStamp - (week_idx - left) * 1000 * 60 * 60 * 24
        }
      }
      // week_idx 为 6时（终止日） 不执行
      if (rightNum > 0) {
        for (let right = 0; right < rightNum; right++) {
          week[right + week_idx + 1].timeStamp = curTimeStamp + (right + 1) * 1000 * 60 * 60 * 24
        }
      }
      // 判断是否今日
      const { year: noWYear, month: nowMonth, day: NowDay } = this.formatDate()
      week.forEach(el => {
        const { year, month, day, date } = this.formatDate(el.timeStamp)
        // 判断是否今日
        const isToday = year === noWYear && month === nowMonth && day === NowDay ? true : false
        el.date = date
        el.year = year
        el.month = month
        el.day = day
        el.isToday = isToday
      })
      this.weekList = JSON.parse(JSON.stringify(week))
    },
    handleShowDay(timeStamp) {
      const { timeStamp: newTimeStamp } = this.formatDate(this.value3)
      const { timeStamp: curTimeStamp, index } = this.getDayTimeStampIndex(timeStamp ? timeStamp : newTimeStamp)
      const { year, month, day, date } = this.formatDate(curTimeStamp)
      this.dayList = {
        year,
        month,
        day,
        date,
        timeStamp: curTimeStamp,
        name: this.weekName[index],
        list: []
      }
    },
    handleShowCalendar() {
      this.showCalendar(this.year, this.month, this.getDayWeekIndex(this.year, this.month))
    },
    changeMonth(idx) {
      if (this.loading) {
        return
      }
      let newMonth = this.month + idx
      if (newMonth > 12) {
        this.month = 1
        this.year++
      } else if (newMonth < 1) {
        this.month = 12
        this.year--
      } else {
        this.month = newMonth
      }
      this.value1 = `${this.year}-${this.fill(this.month)}`
      this.handleShowCalendar()
    },
    changeWeek(idx) {
      if (this.loading) {
        return
      }
      let timeStamp = 7 * 1000 * 60 * 60 * 24,
        NowTimeStamp = this.weekList[0].timeStamp
      if (idx > 0) {
        timeStamp = NowTimeStamp + timeStamp
      } else {
        timeStamp = NowTimeStamp - timeStamp
      }
      this.value2 = timeStamp
      this.handleShowWeek()
    },
    handleChangeYearMonth(e) {
      if (this.loading) {
        return
      }
    },
    handleChangeDate(e) {
      if (this.loading) {
        return
      }
      if (!e) return
      const arr = e.split('-')
      if (arr[2]) {
        this.value3 = e
        this.handleShowDay()
        return
      }
      this.year = +arr[0]
      this.month = +arr[1]
      this.value1 = this.year + '-' + this.fill(this.month)
      this.handleShowCalendar()
    },
    handleChangeType(val) {
      const obj = {
        month: this.handleShowCalendar,
        week: this.handleShowWeek,
        day: this.handleShowDay
      }
      this.showType = val
      obj[val] && obj[val]()
    },
    handleClickMonthDay(item) {
      this.showType = 'day'
      this.value3 = item.date
      this.handleShowDay(item.timeStamp)
    },
    handleShow(item) {
      this.$emit('show', item)
    },
    judgeStatus(item) {
      const { psychology_status } = item
      const obj = {
        2: 'blue',
        3: 'orange',
        4: 'green',
        5: 'red',
        6: 'purple',
        9: 'out-time'
      }
      return obj[psychology_status]
    }
  }
}
</script>
<style lang="scss" scoped>
.calendar {
  // &-wrapper {}
  &-header,
  &-content {
    background-color: #fff;
    border-radius: 20rem;
  }
  &-header {
    padding: 30rem 40rem;
    margin-bottom: 15rem;
    display: flex;
    justify-content: space-between;
    &-l {
      display: flex;
      .mr {
        margin-right: 20rem;
      }
      .ml {
        margin-left: 20rem;
      }
    }
  }
  &-label {
    margin-right: 10rem;
    line-height: 28rem;
    font-size: 13rem;
    color: #666;
  }
  &-select {
    display: flex;
    align-items: center;
  }
  &-icon {
    width: 28rem;
    height: 28rem;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
  &-text {
    font-size: 14rem;
    color: #666;
  }
  &-content {
    .info {
      .p {
        cursor: pointer;
        user-select: none;
      }
    }
    .day-info {
      .day-title {
        height: 80rem;
        padding-left: 60rem;
        border-bottom: solid 1rem #eee;
        display: flex;
        align-items: center;
        .name {
          margin-right: 5rem;
          font-size: 16rem;
          color: #666;
        }
        .date {
          font-size: 12rem;
          color: #ccc;
        }
      }
      .day-content {
        position: relative;
      }
      .day-inner {
        min-height: calc(100vh - 310rem);
        display: flex;
        border-bottom: solid 1rem #eee;
      }
      .time-box {
        width: 160rem;
        border-right: solid 1rem #eee;
        display: flex;
        align-items: stretch;
        flex-shrink: 0;
        .item {
          height: 100rem;
          font-size: 16rem;
          color: #666;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .list-box {
        padding: 10rem;
        flex: 1;
      }
      .list-item {
        padding: 10rem;
        font-size: 12rem;
        line-height: 1.6;
        border-radius: 4rem;
        cursor: pointer;
        display: flex;
        color: #79b3f2;
        background-color: rgba(121, 179, 242, 0.1);
        .status {
          width: 90rem;
          padding-right: 10rem;
          margin-right: 30rem;
          border-right: solid 1rem #eee;
        }
        i {
          margin-right: 6rem;
        }
        &.blue {
          color: #90aaef;
          background-color: rgba(144, 170, 239, 0.1);
        }
        &.orange {
          color: #efb19c;
          background-color: rgba(239, 177, 156, 0.1);
        }
        &.green {
          color: #8ee5a0;
          background-color: rgba(142, 229, 160, 0.1);
        }
        &.red {
          color: #ef8b9a;
          background-color: rgba(239, 139, 154, 0.1);
        }
        &.purple {
          color: #b1a7d9;
          background-color: rgba(177, 167, 217, 0.1);
        }
        &:not(:last-child) {
          margin-bottom: 10rem;
        }
        &.out-time {
          color: #aaa !important;
          background-color: #f5f5f5 !important;
          background-size: 40rem 40rem;
          background-image: linear-gradient(
            -135deg,
            rgba(255, 255, 255, 0.8) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0.8) 75%,
            transparent 75%,
            transparent
          );
        }
      }
    }
  }
  &-row {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: calc(100% / 7);
      // width: calc((100% - 60rem) / 7);
      // &:not(:nth-child(7n)) {
      //   margin-right: 10rem;
      // }
    }
    .info {
      font-size: 12rem;
      line-height: 30rem;
      .p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        margin-bottom: 4rem;
        font-size: 12rem;
        border-radius: 4rem;

        &:nth-child(10n + 1) {
          color: #90aaef;
          background-color: rgba($color: #90aaef, $alpha: 0.1);
          &::before {
            background: #90aaef;
          }
        }
        &:nth-child(10n + 2) {
          color: #ef8b9a;
          background-color: rgba($color: #ef8b9a, $alpha: 0.1);
          &::before {
            background: #ef8b9a;
          }
        }
        &:nth-child(3) {
          color: #8ee5a0;
          background-color: rgba($color: #8ee5a0, $alpha: 0.1);
          &::before {
            background: #8ee5a0;
          }
        } //  '#8EE5A0', '#E67EA0', '#EFB19C', '#9591B4', '#C8AAE5', '#E0D0B7', '#6590FF'
        &:nth-child(10n + 4) {
          color: #efae8e;
          background-color: rgba($color: #efae8e, $alpha: 0.1);
          &::before {
            background: #efae8e;
          }
        }
        &:nth-child(10n + 5) {
          color: #79b3f2;
          background-color: rgba($color: #79b3f2, $alpha: 0.1);
          &::before {
            background: #79b3f2;
          }
        }
        &:nth-child(10n + 6) {
          color: #b1a7d9;
          background-color: rgba($color: #b1a7d9, $alpha: 0.1);
          &::before {
            background: #b1a7d9;
          }
        }
        &:nth-child(10n + 7) {
          color: #d1a994;
          background-color: rgba($color: #d1a994, $alpha: 0.1);
          &::before {
            background: #d1a994;
          }
        }
        &:nth-child(10n + 8) {
          color: #91e35d;
          background-color: rgba($color: #91e35d, $alpha: 0.1);
          &::before {
            background: #91e35d;
          }
        }
        &:nth-child(10n + 9) {
          color: #fac673;
          background-color: rgba($color: #fac673, $alpha: 0.1);
          &::before {
            background: #fac673;
          }
        }
        &:nth-child(10n + 10) {
          color: #efb19c;
          background-color: rgba($color: #efb19c, $alpha: 0.1);
          &::before {
            background: #efb19c;
          }
        }
      }
      .name {
        margin: 0 6rem;
      }
    }
    &.title-row {
      .item {
        height: 80rem;
        font-size: 16rem;
        color: #666;
        display: flex;
        justify-content: center;
        align-items: center;
        &:not(:last-child) {
          border-right: solid 1rem #eee;
        }
      }
    }
    &.month-row {
      .item {
        height: 154rem;
        border-top: solid 1rem #eee;
        &:not(:nth-child(7n)) {
          border-right: solid 1rem #eee;
        }
        &.other {
          .date {
            color: #ddd;
          }
        }
        &.today {
          background-color: rgb(250, 251, 255);
        }
        .date {
          height: 30rem;
          padding-right: 20rem;
          font-size: 16rem;
          line-height: 30rem;
          color: #666;
          text-align: right;
        }
        .info {
          height: 98rem;
          padding: 0 5rem;
          overflow: hidden;
          .p {
            padding-left: 22rem;
            padding-right: 6rem;
            &::before {
              content: '';
              position: absolute;
              left: 14rem;
              top: 10rem;
              bottom: 10rem;
              width: 2rem;
              border-radius: 2rem;
            }
          }
        }
        .arrow {
          height: 26rem;
          color: #999;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          i {
            transform: rotate(-90deg);
          }
        }
      }
    }
    &.week-row {
      .item {
        &:not(:last-child) {
          border-right: solid 1rem #eee;
        }
        &.today {
          background-color: rgb(250, 251, 255);
        }
      }
      .title {
        height: 80rem;
        border-bottom: solid 1rem #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        .name {
          margin-right: 5rem;
          font-size: 16rem;
          color: #666;
        }
        .date {
          font-size: 12rem;
          color: #ccc;
        }
      }
      .info {
        min-height: calc(100vh - 310rem);
        padding: 5rem;
        .p {
          padding: 0 10rem;
        }
      }
    }
  }
}
</style>
